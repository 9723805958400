import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loader from "../field/loader";
import { editTask, getTaskById } from "../../api/task";
import { toastError } from "../common/toast";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import GsheetFileAndRangesFields from "./components/GsheetFileAndRangesFields";
import GsheetRangeAndValuesFields from "./components/GsheetRangeAndValuesFields";
import AnaplanDropdowns from "./components/AnaplanDropdowns";
import { getConnection } from "../../api/connections";
import { useDispatch, useSelector } from "react-redux";
import {
  resetState,
  setConnections,
  setGSheetFileAndRangeDefaultValue,
  setGsheetRangeAndValueDefaultValue,
  setSelectedConnection,
  setTaskAttribValues,
  setTaskDescription,
  setTaskFormDefaultValues,
} from "../../app/features/taskFormSlice/taskFormSlice";
import { ignoreConnectionWarningForTaskTypes } from "../../utils/constants";

const EditTask = () => {
  let navigate = useNavigate();
  const taskFormState = useSelector((state) => state.taskForm);

  const {
    name,
    description,
    taskTypeAttributes,
    selectedTaskId,
    taskTypeName,
    connections,
    selectedConnection,
    taskAttribValues,
    gSheetRangeAndValue,
    gSheetFileAndRangValue,
  } = taskFormState;
  const dispatch = useDispatch();
  // reset state
  useEffect(() => () => dispatch(resetState()), [dispatch]);
  const [loader, setLoader] = useState(false);

  const params = useParams();
  const { id } = params;

  useEffect(
    () => {
      setLoader(true);
      const getTaskType = async () => {
        try {
          const { data } = await getTaskById(id);
          const { data: connections } = await getConnection();
          dispatch(setConnections(connections));
          dispatch(setSelectedConnection(data.connection));

          // gSheet state
          const dataString = data?.taskTypeAttributes?.find(
            (attrib) => attrib?.name === "Data_String"
          );
          const fileString = data?.taskTypeAttributes?.find(
            (attrib) => attrib?.name === "File_String"
          );

          if (dataString) {
            dispatch(setGsheetRangeAndValueDefaultValue(dataString.value));
          }

          if (fileString) {
            // setGSheetFileAndRangeValue(fileString.value);
            dispatch(setGSheetFileAndRangeDefaultValue(fileString.value));
          }
          setLoader(false);
          // setTaskDetails(data);
          dispatch(setTaskFormDefaultValues(data));
        } catch (error) {
          setLoader(false);
          console.log(error);
        }
      };
      getTaskType();
    },
    [dispatch, id],
    dispatch
  );

  const onSubmitHandler = async () => {
    setLoader(true);
    /** Find and add values to Data string and File string which will be used only in case of Gsheet tasks */

    const allAttributeDetails = [];

    taskTypeAttributes.forEach((attrib) => {
      if (
        (selectedTaskId === "15" || selectedTaskId === "16") &&
        attrib.name === "Data_String"
      ) {
        allAttributeDetails.push({
          ...attrib,
          key: attrib.name,
          value: gSheetRangeAndValue,
        });
      } else if (selectedTaskId === "15" && attrib.name === "File_String") {
        allAttributeDetails.push({
          ...attrib,
          key: attrib.name,
          value: gSheetFileAndRangValue,
        });
      } else {
        allAttributeDetails.push({
          ...attrib,
          key: attrib.name,
          value: taskAttribValues[attrib.name],
        });
      }
    });

    const payload = {
      description,
      taskTypeAttributes: allAttributeDetails,
      taskTypeId: selectedTaskId
    };

    if (!ignoreConnectionWarningForTaskTypes.includes(selectedTaskId)) {
      payload.connection = selectedConnection;
    }

    try {
      await editTask(id, payload);
      setLoader(false);
      navigate("/task");
    } catch (error) {
      setLoader(false);
      return toastError(error?.message, { autoClose: 2000 });
    }
  };

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmitHandler(e);
      }}
      bgcolor="background.paper"
      sx={{ minHeight: "90vh", padding: "20px" }}
    >
      <h1 className="page-head">Edit task</h1>
      <FormControl fullWidth>
        <Grid container sx={{ flexDirection: "column" }} spacing={3}>
          <Grid item>
            <TextField
              fullWidth
              label="Task Name"
              required
              value={name}
              inputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: "normal" }}
            />
          </Grid>
          <Grid item>
            <TextField
              multiline
              required
              fullWidth
              name="description"
              label="Description"
              placeholder="Description here.."
              InputLabelProps={{ shrink: "normal" }}
              value={description}
              onChange={(e) => dispatch(setTaskDescription(e.target.value))}
            />
          </Grid>

          {!ignoreConnectionWarningForTaskTypes.includes(selectedTaskId) && (
            <Grid item>
              <TextField
                select
                onChange={(e) =>
                  dispatch(setSelectedConnection(e.target.value))
                }
                fullWidth
                value={selectedConnection}
                InputLabelProps={{ shrink: "normal" }}
                label="Select a connection"
                required
              >
                {connections.map((connection) => (
                  <MenuItem value={connection._id} key={connection._id}>
                    {connection.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          <Grid item>
            <TextField
              fullWidth
              label="Task Type"
              required
              InputLabelProps={{ shrink: "normal" }}
              value={taskTypeName}
              inputProps={{ readOnly: true }}
            />
          </Grid>
          {taskTypeAttributes
            ?.filter((taskAttrib) => taskAttrib.inputField !== "hidden")
            ?.map((item, index) => {
              /** FOR GSHEET TASK Display dynamic field option for data string to create array result type*/
              if (item.name === "Data_String") {
                return (
                  <>
                    <Grid item>
                      <Divider />
                    </Grid>
                    <Grid item>
                      <Typography>Range and values to replace</Typography>
                    </Grid>
                    <GsheetRangeAndValuesFields />
                    <Grid item>
                      <Divider />
                    </Grid>
                  </>
                );
              }

              /** FOR GSHEET TASK Display dynamic field option for file string to create array result type */
              if (item.name === "File_String") {
                return (
                  <>
                    <Grid item>
                      <Divider />
                    </Grid>
                    <Grid item>
                      <Typography>Select files to paste</Typography>
                    </Grid>
                    <GsheetFileAndRangesFields />
                    <Grid item>
                      <Divider />
                    </Grid>
                  </>
                );
              }
              return (
                <Grid item key={item.key}>
                  {item.inputField !== "checkbox" && (
                    <TextField
                      label={item.label || item.key}
                      fullWidth
                      select={item.inputField === "select"}
                      multiline={item.inputField === "textarea"}
                      type={item.inputField}
                      name={item.name}
                      required={item.fieldRequired}
                      placeholder={`Enter Attribute value`}
                      value={taskAttribValues[item.name]}
                      onChange={(e) => {
                        console.log("changed", e.target.value);
                        const obj = {};
                        obj[e.target.name] = e.target.value;

                        dispatch(
                          setTaskAttribValues({
                            ...taskAttribValues,
                            ...obj,
                          })
                        );
                      }}
                    >
                      {/* In case of dropdown render options provided */}
                      {item.inputField === "select"
                        ? item?.options?.map((option) => {
                            return (
                              <MenuItem
                                selected={item.value === option}
                                key={option}
                                value={option}
                              >
                                {option}
                              </MenuItem>
                            );
                          })
                        : null}
                    </TextField>
                  )}

                  {item.inputField === "checkbox" && (
                    <FormControlLabel
                      name={item.name}
                      label={item.label}
                      checked={item.value}
                      onChange={(e) => {
                        const obj = {};
                        obj[e.target.name] = e.target.value;

                        dispatch(
                          setTaskAttribValues({
                            ...taskAttribValues,
                            ...obj,
                          })
                        );
                      }}
                      control={<Checkbox />}
                    />
                  )}
                </Grid>
              );
            })}
          {selectedTaskId === "1" && (
            <AnaplanDropdowns
              actionsTypes={["files"]}
              // taskDetails={taskDetails}
            />
          )}
          {selectedTaskId === "2" && (
            <AnaplanDropdowns
              actionsTypes={["imports", "exports", "processes", "actions"]}
              // taskDetails={taskDetails}
            />
          )}
          {selectedTaskId === "3" && (
            <AnaplanDropdowns
              actionsTypes={["files"]}
              // taskDetails={taskDetails}
            />
          )}

          <Grid
            item
            className="submit-cont"
            style={{ justifyContent: "start", marginTop: 20 }}
          >
            <input type="submit" value="Save" />
          </Grid>
        </Grid>
      </FormControl>
      {loader && <Loader />}
    </Box>
  );
};

export default EditTask;
