import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../components/field/loader";
import { deleteConnection, getConnection } from "../api/connections";
import { DeleteOutlined, EditOutlined } from "@mui/icons-material";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { toastError, toastSuccess } from "../components/common/toast";

const Connection = () => {
  const [connections, setConnections] = useState([]);
  const [loader, setLoader] = useState(false);

  const [itemToDelete, setItemToDelete] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    setLoader(true);
    const getConnections = async () => {
      try {
        const response = await getConnection();

        setLoader(false);
        setConnections(
          response.data.reverse().map((item) => {
            return {
              ...item,
              readMore: "none",
            };
          })
        );
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    };
    getConnections();
  }, []);

  const handleDeleteClick = async () => {
    setLoader(true);
    const payload = {
      connectionId: itemToDelete._id,
    };
    try {
      await deleteConnection(payload);

      setLoader(false);
      setConnections(
        connections.filter((item) => item._id !== itemToDelete._id)
      );
      setItemToDelete(null);
      return toastSuccess("Connection deleted succesfully");
    } catch (error) {
      setLoader(false);
      return toastError(error?.message);
    }
  };

  return (
    <>
      <div>
        <h1 className="page-head">Connection </h1>
        <div className="inner-body-cont">
          <div className="btn-bloat-right">
            <Link className="commn-btn" to="/connection/add-connection">
              Create New
            </Link>
          </div>

          <div className="commn-table-cont table-responsive-md">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Desc.</th>
                  <th scope="col">Type</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {connections.map((item, index) => {
                  return (
                    <tr key={index}>
                      <th className="first-row" scope="row">
                        {item.name}
                      </th>
                      <td className="second-row">
                        <p>
                          <span
                            style={{
                              display:
                                item.readMore === "none" ? "block" : "none",
                            }}
                            className="short-decp"
                          >
                            {item.description.length > 150
                              ? item.description.slice(0, 150)
                              : item.description}
                            <span
                              className="read-more-text"
                              // onClick={() => handleReadMoreClick(index)}
                            >
                              {" "}
                              {item.description.length > 150
                                ? "Read More..."
                                : ""}
                            </span>
                          </span>
                          <span
                            style={{ display: item.readMore }}
                            className="full-text"
                          >
                            {item.description}
                          </span>
                        </p>
                      </td>
                      <td className="third-row">
                        <p>{item.connectionName}</p>
                      </td>
                      <td className="fourth-row">
                        <Link
                          to={`/connection/edit-connection/${item._id}`}
                          className="view-link"
                        >
                          <EditOutlined sx={{ color: "grey" }} />
                        </Link>
                        <span
                          onClick={() => {
                            setItemToDelete(item);
                            setShowConfirmationModal(true);
                          }}
                          className="delete-link"
                        >
                          <DeleteOutlined sx={{ color: "grey" }} />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <ConfirmationDialog
        title="Are you sure you want to delete this?"
        open={showConfirmationModal}
        setOpen={setShowConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
        handleDeleteClick={handleDeleteClick}
        itemName={itemToDelete?.name}
      />
      {loader && <Loader />}
    </>
  );
};

export default Connection;
