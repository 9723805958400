import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../field/loader";
import { addTask, getTaskTypeById, getTaskTypes } from "../../api/task";
import { getConnection } from "../../api/connections";
import { toastError, toastSuccess } from "../common/toast";
import GsheetRangeAndValuesFields from "./components/GsheetRangeAndValuesFields";
import {
  FormControl,
  MenuItem,
  FormControlLabel,
  Grid,
  Box,
  Checkbox,
  TextField,
  Divider,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import GsheetFileAndRangesFields from "./components/GsheetFileAndRangesFields";
import AnaplanDropdowns from "./components/AnaplanDropdowns";
import { useDispatch, useSelector } from "react-redux";
import {
  resetState,
  setAddTaskLoader,
  setConnections,
  setSelectedConnection,
  setSelectedTaskTypeId,
  setTaskAttribValues,
  setTaskDescription,
  setTaskName,
  setTaskTypeAttributes,
  setTaskTypeName,
  setTaskTypes,
} from "../../app/features/taskFormSlice/taskFormSlice";
import { ignoreConnectionWarningForTaskTypes } from "../../utils/constants";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { isValidJson } from "../../utils/helpers";

const AddTask = () => {
  const [showHeaders, setShowHeaders] = useState(false);
  let navigate = useNavigate();
  const taskFormState = useSelector((state) => state.taskForm);

  const {
    name,
    description,
    addTaskLoader,
    taskTypes,
    taskTypeAttributes,
    selectedTaskId,
    taskTypeName,
    connections,
    selectedConnection,
    taskAttribValues,
    gSheetFileAndRangValue,
    gSheetRangeAndValue,
  } = taskFormState;
  const dispatch = useDispatch();
  // reset state
  useEffect(() => () => dispatch(resetState()), [dispatch]);

  useEffect(() => {
    dispatch(setAddTaskLoader(true));
    const fetchTaskTypes = async () => {
      try {
        const response = await getTaskTypes();
        dispatch(setAddTaskLoader(false));
        dispatch(setTaskTypes(response.data));
      } catch (error) {
        dispatch(setAddTaskLoader(false));
        console.log(error);
      }
    };

    const fetchConnections = async () => {
      try {
        const response = await getConnection();
        dispatch(setConnections(response.data));
      } catch (error) {}
    };
    fetchTaskTypes();
    fetchConnections();
  }, [dispatch]);

  const handleTaskTypeChange = async (e) => {
    dispatch(setAddTaskLoader(true));
    dispatch(setSelectedTaskTypeId(e.target.value));
    try {
      const { data } = await getTaskTypeById(e.target.value);
      dispatch(setAddTaskLoader(false));
      dispatch(setTaskTypeAttributes(data.attributes));
      dispatch(setTaskTypeName(data.name));
      return toastSuccess(data.message, { autoClose: 2000 });
    } catch (error) {
      dispatch(setAddTaskLoader(false));
      return toastError(error?.message, { autoClose: 2000 });
    }
  };

  const handleConnectionChange = async (e) => {
    dispatch(setSelectedConnection(e.target.value));
  };

  const onSubmitHandler = async () => {
    if (
      !selectedConnection &&
      !ignoreConnectionWarningForTaskTypes.includes(selectedTaskId)
    ) {
      toastError("Please select a connection");
    }
    // dispatch(setAddTaskLoader(true));
    const allAttributeDetails = [];

    taskTypeAttributes.forEach((attrib) => {
      if (
        (selectedTaskId === "15" || selectedTaskId === "16") &&
        attrib.name === "Data_String"
      ) {
        allAttributeDetails.push({
          ...attrib,
          key: attrib.name,
          value: gSheetRangeAndValue,
        });
      } else if (selectedTaskId === "15" && attrib.name === "File_String") {
        allAttributeDetails.push({
          ...attrib,
          key: attrib.name,
          value: gSheetFileAndRangValue,
        });
      } else {
        allAttributeDetails.push({
          ...attrib,
          key: attrib.name,
          value: taskAttribValues[attrib.name],
        });
      }
    });

    const payload = {
      name: name,
      description: description,
      taskTypeAttributes: allAttributeDetails,
      taskTypeId: selectedTaskId,
      taskTypeName,
      connection: selectedConnection,
    };

    console.log("taskAttribValues", taskAttribValues);

    console.log("payload", payload);
    try {
      const result = await addTask(payload);
      dispatch(setAddTaskLoader(false));
      navigate("/task");
      toastSuccess(result.data.message);
    } catch (error) {
      console.log("got err", error);
      dispatch(setAddTaskLoader(false));
      let errorText = "Something went wrong!";
      if (Array.isArray(error?.response?.data?.message)) {
        const { message } = error.response.data;
        errorText = message.toString();
      } else {
        errorText = error?.response?.data?.message;
      }
      toastError(errorText);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmitHandler(e);
      }}
      bgcolor="background.paper"
      sx={{ minHeight: "90vh", padding: "20px" }}
    >
      <h1 className="page-head">Add Task</h1>
      <FormControl fullWidth>
        <Grid container sx={{ flexDirection: "column" }} spacing={3}>
          <Grid item>
            <TextField
              fullWidth
              label="Name"
              required
              name="name"
              value={name}
              onChange={(e) => dispatch(setTaskName(e.target.value))}
            />
          </Grid>
          <Grid item>
            <TextField
              multiline
              fullWidth
              required
              name="description"
              placeholder="Description here.."
              label="Description"
              onChange={(e) => dispatch(setTaskDescription(e.target.value))}
            />
          </Grid>

          <Grid item>
            <TextField
              select
              label="Select task type"
              fullWidth
              onChange={handleTaskTypeChange}
              value={selectedTaskId}
              required
            >
              {taskTypes.map((item) => (
                <MenuItem value={item.taskTypeId} key={item.taskTypeId}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {!ignoreConnectionWarningForTaskTypes.includes(selectedTaskId) && (
            <Grid item>
              <TextField
                select
                onChange={handleConnectionChange}
                fullWidth
                value={selectedConnection}
                label="Select a connection"
                required
              >
                {connections.map((connection) => (
                  <MenuItem value={connection._id} key={connection._id}>
                    {connection.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}

          {taskTypeAttributes
            .filter((item) => item.inputField !== "hidden")
            .map((item) => {
              /** FOR GSHEET TASK Display dynamic field option for data string to create array result type*/
              if (item.name === "Data_String") {
                return (
                  <>
                    <Grid item>
                      <Divider />
                    </Grid>
                    <Grid item>
                      <Typography>Range and values to replace</Typography>
                    </Grid>
                    <GsheetRangeAndValuesFields />
                    <Grid item>
                      <Divider />
                    </Grid>
                  </>
                );
              }

              /** FOR GSHEET TASK Display dynamic field option for file string to create array result type */
              if (item.name === "File_String") {
                return (
                  <>
                    <Grid item>
                      <Divider />
                    </Grid>
                    <Grid item>
                      <Typography>Select files to paste</Typography>
                    </Grid>
                    <GsheetFileAndRangesFields />
                    <Grid item>
                      <Divider />
                    </Grid>
                  </>
                );
              }

              // for headers show custom input with toggle visibility
              if (item.name === "Headers") {
                return (
                  <>
                    <Grid item>
                      <TextField
                        label={item.label || item.name}
                        variant="outlined"
                        type={showHeaders ? "text" : "password"}
                        fullWidth
                        error={
                          taskAttribValues?.Headers &&
                          !isValidJson(taskAttribValues.Headers)
                        }
                        name={item.name}
                        onChange={(e) => {
                          const obj = {};

                          obj[e.target.name] = e.target.value;

                          dispatch(
                            setTaskAttribValues({ ...taskAttribValues, ...obj })
                          );
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowHeaders((prev) => !prev)}
                              >
                                {showHeaders ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </>
                );
              }
              return (
                <Grid item>
                  {item.inputField !== "checkbox" && (
                    <TextField
                      fullWidth
                      select={item.inputField === "select"}
                      label={item.label || item.name}
                      multiline={item.inputField === "textarea"}
                      type={item.inputField}
                      onChange={(e) => {
                        const obj = {};

                        obj[e.target.name] = e.target.value;

                        dispatch(
                          setTaskAttribValues({ ...taskAttribValues, ...obj })
                        );
                      }}
                      name={item.name}
                      required={item.fieldRequired}
                      placeholder={`Enter Attribute value`}
                    >
                      {/* In case of dropdown render options provided */}
                      {item.inputField === "select" &&
                        item?.options?.map((option) => {
                          return (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  )}
                  {item.inputField === "checkbox" && (
                    <FormControlLabel
                      name={item.name}
                      label={item.label}
                      onChange={(e) => {
                        console.log("go", e);
                        const { name, checked } = e.target;
                        const obj = {};
                        obj[name] = checked;
                        dispatch(
                          setTaskAttribValues({ ...taskAttribValues, ...obj })
                        );
                      }}
                      control={<Checkbox />}
                    />
                  )}
                </Grid>
              );
            })}

          {/* Display dropdown options for taskTypes [Anaplan Upload, Anaplan Action, Anaplan Download] */}
          {selectedTaskId === "1" && (
            <AnaplanDropdowns actionsTypes={["files"]} />
          )}
          {selectedTaskId === "2" && (
            <AnaplanDropdowns
              actionsTypes={["imports", "exports", "processes", "actions"]}
            />
          )}
          {selectedTaskId === "3" && (
            <AnaplanDropdowns actionsTypes={["files"]} />
          )}
          <Grid
            item
            className="submit-cont"
            style={{ justifyContent: "start", marginTop: 20 }}
          >
            <input type="submit" value="Save" />
          </Grid>
          {addTaskLoader && <Loader />}
        </Grid>
      </FormControl>
    </Box>
  );
};

export default AddTask;
