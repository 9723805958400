import { http } from "./https";
const { post } = http;

export const createUser = async (payload) => {
  const response = await post(`create-user`, payload);
  return response;
};

export const getUserRole = async () => {
  const response = await post('get-user-role');
  return response;
}
