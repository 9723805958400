import { Route } from "react-router";
import AdminRoute from "../components/HOC/AdminRoute";
import Layout from "../components/layout/layout";
import AddSchedule from "../components/schedule/AddSchedule";
import EditSchedule from "../components/schedule/EditSchedule";
import Schedule from "../pages/Schedule";
import ViewDetails from "../pages/ViewDetails";

const ScheduleRoutes = [
  <Route
    path="/schedule"
    key="/schedule"
    element={
      <Layout>
        <AdminRoute>
          <Schedule />
        </AdminRoute>
      </Layout>
    }
  />,
  <Route
    path="/schedule/:add-schedule"
    key="/schedule/:add-schedule"
    element={
      <Layout>
        <AdminRoute>
          <AddSchedule />
        </AdminRoute>
      </Layout>
    }
  />,
  <Route
    path="/schedule/view-schedule/:id"
    key="/schedule/view-schedule/:id"
    element={
      <Layout>
        <AdminRoute>
          <ViewDetails />
        </AdminRoute>
      </Layout>
    }
  />,
  <Route
    path="/schedule/:edit-schedule/:id"
    key="/schedule/:edit-schedule/:id"
    element={
      <Layout>
        <AdminRoute>
          <EditSchedule />
        </AdminRoute>
      </Layout>
    }
  />,
];

export default ScheduleRoutes;
