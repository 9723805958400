import { Route } from "react-router";
import AddConfigVar from "../components/ConfigVar/AddConfigVar";
import EditConfigVar from "../components/ConfigVar/EditConfigVar";
import AdminRoute from "../components/HOC/AdminRoute";
import Layout from "../components/layout/layout";
import ConfigVar from "../pages/ConfigVar";

const ConfigVarRoutes = [
  <Route
    path="/configVar"
    key="/configVar"
    element={
      <Layout>
        <AdminRoute>
          <ConfigVar />
        </AdminRoute>
      </Layout>
    }
  />,

  <Route
    path="/configVar/add-configVar"
    key="/configVar/add-configVar"
    element={
      <Layout>
        <AdminRoute>
          <AddConfigVar />
        </AdminRoute>
      </Layout>
    }
  />,

  <Route
    path="/configVar/edit-configVar/:id"
    key="/configVar/edit-configVar/:id"
    element={
      <Layout>
        <AdminRoute>
          <EditConfigVar />
        </AdminRoute>
      </Layout>
    }
  />,
];

export default ConfigVarRoutes;
