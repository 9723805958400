import { Route } from "react-router";
import AddConnection from "../components/connection/AddConnection";
import EditConnection from "../components/connection/EditConnection";
import AdminRoute from "../components/HOC/AdminRoute";
import Layout from "../components/layout/layout";
import Connection from "../pages/Connection";
import ViewDetails from "../pages/ViewDetails";

const ConnectionRoutes = [
  <Route
    path="/connection-type/view-connection-type/:id"
    key="/connection-type/view-connection-type/:id"
    element={
      <Layout>
        <ViewDetails />
      </Layout>
    }
  />,

  <Route
    path="/connection"
    key="/connection"
    element={
      <Layout>
        <AdminRoute>
          <Connection />
        </AdminRoute>
      </Layout>
    }
  />,
  <Route
    path="/connection/edit-connection/:id"
    key="/connection/edit-connection/:id"
    element={
      <Layout>
        <AdminRoute>
          <EditConnection />
        </AdminRoute>
      </Layout>
    }
  />,
  <Route
    path="/connection/:add-connection"
    key="/connection/:add-connection"
    element={
      <Layout>
        <AdminRoute>
          <AddConnection />
        </AdminRoute>
      </Layout>
    }
  />,
];

export default ConnectionRoutes;
