import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../components/field/loader";
import { deleteTask, getTask } from "../api/task";
import {
  AssignmentOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@mui/icons-material";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { toastError, toastSuccess } from "../components/common/toast";
import { setSelectedTask, setTasks } from "../app/features/task/taskSlice";
import { useDispatch, useSelector } from "react-redux";

const Task = () => {
  // const [tasks, setTasks] = useState([]);
  const taskState = useSelector((state) => state.task);
  const { tasks } = taskState;
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  const [itemToDelete, setItemToDelete] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    const getTasks = async () => {
      try {
        setLoader(true);
        const response = await getTask();
        setLoader(false);
        dispatch(setTasks(response.data.reverse()));
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    };

    getTasks();
  }, []);

  const handleDeleteClick = async () => {
    console.log("itemToDelete", itemToDelete);
    const payload = {
      taskId: itemToDelete._id,
    };
    try {
      const result = await deleteTask(payload);
      toastSuccess(result.data.message, { autoClose: 2000 });
      dispatch(setTasks(tasks.filter((item) => item._id !== itemToDelete._id)));
      setItemToDelete(null);
      setShowConfirmationModal(false);
      return;
    } catch (error) {
      setShowConfirmationModal(false);
      return toastError(error?.message, { autoClose: 2000 });
    }
  };

  const handleReadMoreClick = (index) => {
    const readMoreHandle = [...tasks];
    readMoreHandle[index].readMore = "block";
    setTasks(readMoreHandle);
  };

  return (
    <>
      <div>
        <h1 className="page-head">Task </h1>
        <div className="inner-body-cont">
          <div className="btn-bloat-right">
            <Link className="commn-btn" to="/task/add-task">
              Create New
            </Link>
          </div>

          <div className="commn-table-cont table-responsive-md">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Desc.</th>
                  <th scope="col">Type</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {tasks.map((item, index) => {
                  return (
                    <tr key={index}>
                      <th className="first-row" scope="row">
                        {item.name}
                      </th>
                      <td className="second-row">
                        <p>
                          <span
                            style={{
                              display:
                                item.readMore === "none" ? "block" : "none",
                            }}
                            className="short-decp"
                          >
                            {item.description.length > 150
                              ? item.description.slice(0, 150)
                              : item.description}
                            <span
                              className="read-more-text"
                              onClick={() => handleReadMoreClick(index)}
                            >
                              {" "}
                              {item.description.length > 150
                                ? "Read More..."
                                : ""}
                            </span>
                          </span>
                          <span
                            style={{ display: item.readMore }}
                            className="full-text"
                          >
                            {item.description}
                          </span>
                        </p>
                      </td>
                      <td className="third-row">
                        <p>{item.taskTypeName}</p>
                      </td>
                      <td className="fourth-row">
                        <Link
                          to={`/task/view-task/${item._id}`}
                          state={{ tab: "task", name: item.name }}
                          className="view-link"
                        >
                          <AssignmentOutlined sx={{ color: "grey" }} />
                        </Link>
                        <Link
                          onClick={() => dispatch(setSelectedTask(item))}
                          to={`/task/edit-task/${item._id}`}
                          className="view-link"
                        >
                          <EditOutlined sx={{ color: "grey" }} />
                        </Link>
                        <span
                          className="delete-link"
                          onClick={() => {
                            setItemToDelete(item);
                            setShowConfirmationModal(true);
                          }}
                        >
                          <DeleteOutlined />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* <Modal deleteHandler={() => handleDeleteClick(taskId)} /> */}
      <ConfirmationDialog
        title="Are you sure you want to delete this?"
        open={showConfirmationModal}
        setOpen={setShowConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
        handleDeleteClick={handleDeleteClick}
        itemName={itemToDelete?.name}
      />
      {loader && <Loader />}
    </>
  );
};

export default Task;
