import {
  CalendarMonthOutlined,
  LogoutOutlined,
  MonitorOutlined,
  PersonOutlined,
  SchemaOutlined,
  SettingsOutlined,
  TaskOutlined,
  WifiOutlined,
} from "@mui/icons-material";
import { signOut } from "firebase/auth";
import { NavLink, useNavigate } from "react-router-dom";
import { auth } from "../../firebase-config";
import { toastError, toastSuccess } from "../common/toast";

const MainNavigation = () => {
  const navigate = useNavigate();
  const logoutUser = async () => {
    try {
      sessionStorage.setItem("Auth key", "");
      localStorage.removeItem("firebaseIdToken");
      await signOut(auth);
      navigate("/login");
      return toastSuccess("Logout Successfully", { autoClose: 2000 });
    } catch (err) {
      return toastError("Failed to logout");
    }
  };
  return (
    <>
      <nav className="main-nav navbar navbar-expand-lg navbar-light bg-light">
        <p className="sb-title">NAVIGATION</p>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarText">
          <ul className="navbar-nav d-flex flex-md-column">
            <li className="nav-item">
              <NavLink
                to="/monitor"
                className={(navData) => (navData.isActive ? "active" : "")}
              >
                <MonitorOutlined sx={{ color: "grey", mr: 1 }} />
                Dashboard
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/connection"
                className={(navData) => (navData.isActive ? "active" : "")}
              >
                <WifiOutlined sx={{ color: "grey", mr: 1 }} />
                Connection
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/task"
                className={(navData) => (navData.isActive ? "active" : "")}
              >
                <TaskOutlined sx={{ color: "grey", mr: 1 }} />
                Task
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/flow"
                className={(navData) => (navData.isActive ? "active" : "")}
              >
                <SchemaOutlined sx={{ color: "grey", mr: 1 }} />
                Flow
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/schedule"
                className={(navData) => (navData.isActive ? "active" : "")}
              >
                <CalendarMonthOutlined sx={{ color: "grey", mr: 1 }} />
                Schedule
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/configVar"
                className={(navData) => (navData.isActive ? "active" : "")}
              >
                <SettingsOutlined sx={{ color: "grey", mr: 1 }} />
                Config Variables
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/register-user"
                className={(navData) => (navData.isActive ? "active" : "")}
              >
                <PersonOutlined sx={{ color: "grey", mr: 1 }} />
                Add User
              </NavLink>
            </li>
            <div className="divider" />
            <li className="nav-item">
              <div className="logout" onClick={logoutUser}>
                <LogoutOutlined sx={{ color: "grey", mr: 1 }} />
                Logout
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default MainNavigation;
