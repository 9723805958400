import { Route } from "react-router";
import AdminRoute from "../components/HOC/AdminRoute";
import Layout from "../components/layout/layout";
import AddTask from "../components/task/AddTask";
import EditTask from "../components/task/EditTask";
import Task from "../pages/Task";
import ViewDetails from "../pages/ViewDetails";

const TaskRoutes = [
  <Route
    path="/task"
    key="/task"
    element={
      <Layout>
        <AdminRoute>
          <Task />
        </AdminRoute>
      </Layout>
    }
  />,
  <Route
    path="/task/edit-task/:id"
    key="/task/edit-task/:id"
    element={
      <Layout>
        <AdminRoute>
          <EditTask />
        </AdminRoute>
      </Layout>
    }
  />,
  <Route
    path="/task/view-task/:id"
    key="/task/view-task/:id"
    element={
      <Layout>
        <AdminRoute>
          <ViewDetails />
        </AdminRoute>
      </Layout>
    }
  />,
  <Route
    path="/task/:add-task"
    key="/task/:add-task"
    element={
      <Layout>
        <AdminRoute>
          <AddTask />
        </AdminRoute>
      </Layout>
    }
  />,
];

export default TaskRoutes;
