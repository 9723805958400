import axios from 'axios';
import { DEFAULT_ERROR_NOTIFICATION } from '../utils/constants';
import { auth } from '../firebase-config';
import { Navigate } from 'react-router';
import { toastError } from '../components/common/toast';

const API_BASE_URI =
  process.env.REACT_APP_BACKEND_URL;

const http = axios.create({
  baseURL: `${API_BASE_URI}/api`,
});

http.interceptors.request.use(async function (config) {
  // Get fresh token every request
  const newToken = await auth?.currentUser?.getIdToken(true)

  if (newToken) {
    localStorage.setItem("firebaseIdToken", newToken)
  }
  const storedToken = localStorage.getItem("firebaseIdToken")
  if (newToken || storedToken) {
    config.headers.common['Authorization'] = newToken ? newToken : storedToken
  }

  return config;
});

// notify the store that the JWT token is no longer valid in case of HTTP 401
http.interceptors.response.use(
  (response) => response,
  (error) => {
    const response = error.response;

    if (!response) {
      const message = 'Unable to communicate with the server';
      toastError(message);
      console.error(message);

      return Promise.reject(error);
    }

    switch (response.status) {

      case 403:
        toastError('You need to login again');
        <Navigate replace to="/login" />
        
        break;

      case 406:
        toastError('Invalid or missing values');
        break;
      
      default:
        toastError(response?.data?.message || DEFAULT_ERROR_NOTIFICATION)
    }

    return Promise.reject(error);
  }
);

export { http };
