import React, { useState } from "react";
import Loader from "../components/field/loader";
import { roles } from "../utils/constants";
import { createUser } from "../api/admin";
import { toastError, toastSuccess } from "../components/common/toast";
import { Box, FormControl, Grid, MenuItem, TextField } from "@mui/material";
const DEFAULT_STATE = {
  name: "",
  email: "",
  password: "",
  role: "",
};

const Register = () => {
  const [loader, setLoader] = useState(false);
  const [formState, setFormState] = useState(DEFAULT_STATE);

  const registerButtonHandler = async () => {
    setLoader(true);
    const { name, email, password, role } = formState;

    if (!role)
      return [
        toastError("Please Select User Role", { autoClose: 2000 }),
        setLoader(false),
      ];

    try {
      const payload = {
        name,
        email,
        password,
        role,
      };
      const { data } = await createUser(payload);

      return [
        setLoader(false),
        toastSuccess(data.message),
        setFormState(DEFAULT_STATE),
      ];
    } catch (error) {
      return [setLoader(false), toastError(error?.message)];
    }
  };

  const onInputChangeHandler = (e) =>
    setFormState({ ...formState, [e.target.name]: e.target.value });

  return (
    <>
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          registerButtonHandler(e);
        }}
        bgcolor="background.paper"
        sx={{ minHeight: "90vh", padding: "20px" }}
      >
        <h1 className="page-head">Register</h1>
        <FormControl fullWidth>
          <Grid container sx={{ flexDirection: "column" }} spacing={3}>
            <Grid item>
              <TextField
                type="text"
                label="Name"
                fullWidth
                name="name"
                placeholder="Name"
                required
                onChange={onInputChangeHandler}
                value={formState.name}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Email"
                type="email"
                fullWidth
                name="email"
                placeholder="Email"
                required
                onChange={onInputChangeHandler}
                value={formState.email}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="Password"
                type="password"
                name="password"
                placeholder="Password"
                required
                onChange={onInputChangeHandler}
                value={formState.password}
              />
            </Grid>
            <Grid item>
              <TextField
                select
                label="Select Role"
                required
                onChange={onInputChangeHandler}
                value={formState.role}
                name="role"
                className="form-control"
              >
                {roles.map((role) => (
                  <MenuItem value={role.value}>{role.label}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              className="submit-cont"
              style={{ justifyContent: "start", marginTop: 20 }}
            >
              <input type="submit" value="Register" />
            </Grid>
          </Grid>
        </FormControl>
      </Box>
      {loader && <Loader />}
    </>
  );
};

export default Register;
