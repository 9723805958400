import { Route } from "react-router";
import AddFlow from "../components/flow/AddFlow";
import EditFlow from "../components/flow/EditFlow";
import AdminRoute from "../components/HOC/AdminRoute";
import Layout from "../components/layout/layout";
import Flow from "../pages/Flow";
import ViewDetails from "../pages/ViewDetails";

const FlowRoutes = [
  <Route
    path="/flow"
    key="/flow"
    element={
      <Layout>
        <AdminRoute>
          <Flow />
        </AdminRoute>
      </Layout>
    }
  />,
  <Route
    path="/flow/:add-flow"
    key="/flow/:add-flow"
    element={
      <Layout>
        <AdminRoute>
          <AddFlow />
        </AdminRoute>
      </Layout>
    }
  />,
  <Route
    path="/flow/view-flow/:id"
    key="/flow/view-flow/:id"
    element={
      <Layout>
        <AdminRoute>
          <ViewDetails />
        </AdminRoute>
      </Layout>
    }
  />,
  <Route
    path="/flow/:edit-flow/:id"
    key="/flow/:edit-flow/:id"
    element={
      <Layout>
        <AdminRoute>
          <EditFlow />
        </AdminRoute>
      </Layout>
    }
  />,
];

export default FlowRoutes;
