import { Route } from "react-router";
import FileExplorer from "../components/FileExplorer";
import ListFiles from "../components/FileExplorer/ListFiles";

const FileExplorerRoutes = [
  <Route
    path="/file-explorer"
    key="/file-explorer"
    element={<FileExplorer />}
  />,

  <Route
    path="/file-explorer/:folder"
    key="/file-explorer"
    element={<ListFiles />}
  />,
];

export default FileExplorerRoutes;
